.circle-0{    
    height: 10px;
    width: 10px;
    background-color: #12A1FD;
    border-radius: 50%;
    display: inline-block;
}

.circle-1{    
    height: 10px;
    width: 10px;
    background-color: #5B75F8;
    border-radius: 50%;
    display: inline-block;
}

.circle-2 {    
    height: 10px;
    width: 10px;
    background-color: #FF9A32;
    border-radius: 50%;
    display: inline-block;
}
.card-title {
  font-style: normal !important;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 22px !important;
  margin: 20px 20px 20px 20px;
}

.card-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #a0a0a0;
}

.card {
  border-radius: 10px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000839 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #f5b95d;
}

.input-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn-create-user {
  width: 121px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 47px;
}

.btn-cancel {
  width: 124px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #00a8cc !important;
  border-radius: 5px;
}

.title {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.padding-filter {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-create-user {
  width: 146px;
  height: 43px;

  border-radius: 5px;
}

.check-pin-status-text {
  margin-top: 10px;
  color: red;
}

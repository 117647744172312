.admin-layout {
  width: 100%;

  height: auto;
  min-height: 100%;

  position: relative;
  /* overflow: visible !important; */
}

.admin-header {
  height: 50px;
  width: calc(100% - 230px);

  position: fixed;
  top: 0;
  left: 250px;
  z-index: 3;

  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.admin-sider__logo-wrapper .admin-default-logo,
.admin-sider__logo-wrapper img {
  height: 50px;
  transition: 0.3s ease-in-out;
}

.admin-header__content {
  width: 100%;
  height: 100%;
}

.admin-sider {
  position: fixed;
  left: 0;
  top: 0;
  width: 260px;
  height: 100%;
  z-index: 2;
  overflow: scroll;
  transition: 0.3s ease-in-out;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important; */
}

/* width */
::-webkit-scrollbar {
  width: 9px !important;
  height:9px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(77, 77, 77); 
  border-radius: 15px;
}



.admin-sider__logo-wrapper {
  width: 100%;
  height: 100%;
  max-height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  padding: 0 15px;
}

.admin-sider__brand-wrapper {
  display: inline-block;
  margin-left: 10px;

  color: #fff;
  font-size: 16px;
}

.admin-content {
  margin-top: 50px;
  margin-left: 250px;

  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  
}

.admin-content__inner {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 1500px;
  background-color: #f8f8f8;

  
}

body.sider-compact .admin-header {
  width: 100%;
  left: 74px;
  z-index: 1;
}

body.sider-compact .admin-sider {
  width: 74px;
  z-index: 2;
}

body.sider-compact .admin-content {
  margin-left: 74px;
}

body.sider-compact .admin-sider__logo-wrapper img,
body.sider-compact .admin-sider__logo-wrapper .admin-default-logo {
  height: 45px;
}

body.sider-compact .admin-sider .admin-sider__brand-wrapper {
  display: none;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #fff !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #fff !important;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
  background-color: #fff !important;
  z-index: 111;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff !important;
  padding-left: 20px;
  border-radius: 4px;
  border: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-menu-item {
  list-style-type: none;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.sidebar-menu-tree {
  cursor: pointer;
}

.sidebar-menu-tree__caret-container {
  position: absolute;
  right: 20px;
  top: 50%;

  opacity: 1;
  visibility: visible;

  transition: 0.2s ease-out;
  transform: translateY(-50%) rotate(0);
}

.sidebar-menu-tree.open .sidebar-menu-tree__caret-container {
  transform: translateY(-50%) rotate(-90deg);
}

.sidebar-tree {
  padding: 0;
  padding-left: 1.5rem;
  list-style: none;
  margin: 0;

  background-color: #fff;

  transition: 0.2s ease-out;
}

.sidebar-menu-tree > p:hover,
.sidebar-menu-tree > p.active {
  background-color: #fff !important;
}

.sidebar-tree > li > a,
.sidebar-tree > li > p,
.sidebar-tree > li > span,
.sidebar-tree > li > b {
  padding: 0.4rem 0.8rem;
  margin: 0;
  position: relative;
  display: block;

  color: #c2c7d0;
  margin-bottom: 0.2rem;
  background-color: transparent;
  border-radius: 0px;

  transition: 0.2s ease-out;
}

.sidebar-tree > li > a:hover,
.sidebar-tree > li > p:hover,
.sidebar-tree > li > span:hover,
.sidebar-tree > li > b:hover {
  color: #494e54;
  /* background-color: #f5b95d; */
  border-right: 2px solid #f5b95d;
}

.sidebar-tree > li > a.active,
.sidebar-tree > li > p.active,
.sidebar-tree > li > span.active,
.sidebar-tree > li > b.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: #494e54;
  border-right: 2px solid #f5b95d;
}

.sidebar-menu-tree__title {
  display: inline-block;
  vertical-align: middle;

  opacity: 1;
  visibility: visible;

  margin-left: 12px;

  transition: 0.2 ease-out;
}

.sidebar-menu-tree__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 17px;
  vertical-align: middle;
  margin-left: 8px;
}

.collapse-menu {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

body.sider-compact .sidebar-menu-tree__title,
body.sider-compact .sidebar-menu-tree__caret-container {
  opacity: 0;
  visibility: hidden;
}

body.sider-compact .sidebar-tree {
  padding-left: 0.5rem;
}

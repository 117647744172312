@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.vertical-center {
    margin: 0;
    position: absolute;
    margin-top: 50%;
    width: 70%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden !important;

}

.background-login {
    background-color:#FFE9C8; 
    height: 100vh !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important

}

.btn-login {
    margin-top: 40px;
    border-radius: 5px;
    height: 46px;
}

.form-margin{
    margin-top: 20px;
}

.label-margin {
    margin-top: 10px;
    height: 46px !important;
}

.subtitle-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
}

.title-text {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}

.logo-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

    margin-bottom: 50px;
} 
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.vertical-center {
    margin: 0;
    margin-top : 0 !important;
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%); 
    transform: translate(-50%, -50%);
}

.login-container {
    background-color:#FFE9C8 !important; 
    min-height:98vh;
    margin-bottom: 0px !important;
    height: auto;
}

.btn-login {
    margin-top: 40px;
    border-radius: 5px;
    height: 46px;
}

.form-margin{
    margin-top: 20px;
}

.label-margin {
    margin-top: 10px;
    height: 46px !important;
}

.subtitle-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
}

.title-text {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}
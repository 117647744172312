.profile-setting-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0 20px 0;
    
    color: #000839;
}

.card-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #A0A0A0;
}

.card {
    border-radius: 10px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000839 !important;
    background-color: #fff !important;
    border-bottom: 2px solid #F5B95D;
}

.btn-save-profile {
    width: 108px !important;
    height: 46px !important;

    background: #00A8CC;
    border-radius: 5px;
}
.asset-report {
  .addor {
    background: #ffffff;
    border: 1px solid #00a8cc;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #00a8cc;
    height: 43px;
    padding: 0 16px;
    width: fit-content;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  .box {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    .tabs {
      display: flex;
      padding: 24px 24px 0 24px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 16px;
      .childtab {
        margin-right: 24px;
        cursor: pointer;
        padding-bottom: 16px;
      }
      .active {
        border-bottom: 2px solid #f5b95d;
      }
    }
    .head {
      padding: 24px;
      border-bottom: 1px solid #f0f0f0;
      form {
        display: flex;
        align-items: center;
        .radiocustom {
          font-size: 14px;
          input {
            margin-right: 16px;
          }
        }
        label {
          margin-right: 8px;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 0;
        }
        .custext {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          width: 240px;
          height: 35px;
          font-size: 14px;
          padding: 0 12px;
        }
        .cus-sel {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          width: 240px;
          height: 35px;
          font-size: 14px;
        }
        .wrap-inp {
          margin-bottom: 16px;
          margin-right: 24px;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          .wrapsel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              width: 170px;
            }
            .right {
              width: 248px;
            }
          }
        }
        button {
          width: 69px;
          height: 35px;
          background: #ffffff;
          border: 1px solid #00a8cc;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #00a8cc;
        }
        .addorrev {
          width: 69px;
          height: 35px;
          background: #00a8cc;
          border: 1px solid #00a8cc;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: white;
        }
      }
    }
    .con {
      padding: 24px;
      .filter {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;
        color: #a0a0a0;
        .result {
          .sel {
            margin-left: 8px;
            width: 64px;
            height: 30px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      table {
        thead {
          tr {
            text-transform: uppercase;
            font-style: normal;
            font-weight: normal !important;
            font-size: 14px;
            line-height: 19px;
            border: none;
            background: rgba(245, 185, 93, 0.05) !important;
            th {
              border-bottom: unset;
            }
          }
        }
        tbody {
          tr {
            font-size: 14px;
            line-height: 19px;
            color: #a0a0a0;
            .action {
              svg {
                cursor: pointer;
                margin: 0 10px;
                width: 20px;
                height: 20px;
              }
            }
            img {
              width: 80px;
              height: 54px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
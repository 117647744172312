.button-export {
  width: 138px;
  height: 43px;

  margin: 0 0 0 20px;
}

.card-container {
  padding: 20px;
}

.howToOrder {
  .addor {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    font-family: Open Sans;
    border: none;
  }
  .box {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    .head {
      padding: 24px;
      border-bottom: 1px solid #f0f0f0;
      form {
        display: flex;
        align-items: center;
        .wrap-inp {
          margin-right: 24px;
          label {
            margin-right: 8px;
            font-size: 14px;
            line-height: 19px;
          }
          input {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 5px;
            width: 200px;
            height: 35px;
            padding-left: 12px;
            font-size: 14px;
          }
        }
        button {
          width: 69px;
          height: 35px;
          background: #ffffff;
          border: 1px solid #00a8cc;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #00a8cc;
        }
      }
    }
    .con {
      padding: 24px;
      .filter {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;
        color: #a0a0a0;
        .result {
          .sel {
            margin-left: 8px;
            width: 64px;
            height: 30px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      table {
        thead {
          tr {
            text-transform: uppercase;
            font-style: normal;
            font-weight: normal !important;
            font-size: 14px;
            line-height: 19px;
            border: none;
            background: rgba(245, 185, 93, 0.05) !important;
            th {
              border-bottom: unset;
            }
          }
        }
        tbody {
          tr {
            font-size: 14px;
            line-height: 19px;
            color: #a0a0a0;
            .action {
              svg {
                cursor: pointer;
                margin: 0 10px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.addHowToOrderForm {
  // OVERIDE MD EDITOR
  .rc-md-editor.full {
    margin: 0 15px;
    width: 100%;
    height: 400px !important;
    position: static !important;
  }
  ////////////////////
  .title-link {
    display: flex;
    align-items: center;
    svg {
      height: 25px;
      //   width: 25px;
      margin-right: 20px;
      cursor: pointer;
      color: black;
    }
  }
  .box {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    .titbox {
      margin-bottom: 24px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000839;
      padding-bottom: 24px;
      border-bottom: 1px solid #f0f0f0;
    }
    .forms {
      padding: 16px 0;
      border-bottom: 1px solid #f0f0f0;
      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000839;
      }
      input {
        width: 400px;
        height: 35px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 8px 10px;
        &::placeholder {
          color: #a0a0a0;
        }
      }
      textarea {
        border: 1px solid #e0e0e0;
        width: 400px;
      }
      .uploadfilebox {
        cursor: pointer;
        width: 105px;
        height: 105px;
        background: #ffffff;
        border: 1px dashed #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #a0a0a0;
        flex-direction: column;
        img {
          margin-bottom: 12px;
        }
      }
    }
  }
  .wrap-buttons {
    margin-top: 24px;
    button {
      color: #00a8cc;
      width: 124px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #00a8cc;
      box-sizing: border-box;
      border-radius: 5px;
      &:last-of-type {
        background: #00a8cc;
        color: white;
        margin-left: 16px;
      }
    }
  }
}

// OVERIDE MD EDITOR
.rc-md-editor.full {
  margin: 0 15px;
  width: 100%;
  height: 400px !important;
  position: static !important;
}
////////////////////
